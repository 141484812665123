import React, { useState } from 'react';
import { Container, Row, Col,Form, Breadcrumb } from 'react-bootstrap';
import HTMLReactParser from "html-react-parser";
import { Link } from 'gatsby';
import { GetURL } from '../utils';
import PlayVideo from '../PlayVideo/PlayVideo';
import "./Banner.scss";
import "./VideoBanner.scss";
import ImageTransform from '../common/ggfx-client/module/components/image-transform';
import ReapitBook from '../ReapitBook';
import $ from "jquery";

const VideoLandingPage = (props) => {
    const [isPlaying, setPlaying] = useState(false);

    function fullScreenHandler() {
        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if(fullscreenElement == null) {
            setPlaying(false);
        }
    }

    React.useEffect(() => {
        document.addEventListener("fullscreenchange", fullScreenHandler, false);
        document.addEventListener("webkitfullscreenchange", fullScreenHandler, false);
        document.addEventListener("MSFullscreenChange", fullScreenHandler, false);
        document.addEventListener("mozfullscreenchange", fullScreenHandler, false);
    }, []);

    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Banner_Image_Transforms;
    }
    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<span>${props?.data?.Italic_Text}</span>`)
        titleText = HTMLReactParser(titleText)
    }
    return (
        <React.Fragment>            
            <section className='banner'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='banner-content'>
                                <h1>{titleText}</h1>
                                <div className="banner-form">
                                   <p>{props?.data?.Description}</p>
                                    <div className="input-box">
                                        {props?.modules?.filter(c => c.List_of_Links != undefined)[0]?.List_of_Links.map((url, i) => 
                                            <React.Fragment key={i}>
                                                {url?.Link_Type == "Book_A_Valuation" && 
                                                    <ReapitBook className='btn cursor-pointer' type="[&quot;sales&quot;,&quot;lettings&quot;]">
                                                        {url.Sub_Menu_Label}
                                                    </ReapitBook>
                                                }
                                                {url?.Link_Type == "Anchor" && 
                                                    <a href="javascript:;" onClick={() => {
                                                        $('html, body').animate({
                                                            scrollTop: $(url?.Anchor_or_External).offset().top-130
                                                        }, 0)
                                                    }} className='btn cursor-pointer'>{url.Sub_Menu_Label}</a>
                                                }
                                                {url?.Link_Type == "External" && 
                                                    <a href={url?.Anchor_or_External} target="_blank" className='btn cursor-pointer'>{url.Sub_Menu_Label}</a>
                                                }
                                                {url?.Sub_Menu_Link && <Link to={GetURL(url?.Sub_Menu_Link?._id)} className='btn'>{url.Sub_Menu_Label}</Link>}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                               
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="banner-video">
                    <div className="video-banner">
                        <div className="position-relative">
                            {props?.data?.Banner_Image?.url && <ImageTransform
                                imagesources={props?.data?.Banner_Image?.url}
                                renderer="pic-src" 
                                imagename={props.imagename}
                                attr={{ alt: props?.data?.Banner_Image?.alternativeText ? props?.data?.Banner_Image?.alternativeText : props?.data?.Title, className: 'video-bg-img' }}
                                imagetransformresult={processedImages}
                                id={props?.data?._id}
                            />}
                            {props?.data?.Video_Url && <strong
                                className="play-btn"
                                onClick={(e) => {
                                    setPlaying(true);
                                }}
                            >
                                <i className="video-icon"></i>
                            </strong>}
                        </div>
                    </div>
                    {(props?.data?.Video_Url && isPlaying) && <div>
                        {isPlaying &&
                            <React.Fragment>
                                <PlayVideo
                                    isOpen={isPlaying}
                                    videoId={props?.data?.Video_Url}
                                    stopPlay={setPlaying}
                                    width={'100%'} height={'100%'}
                                    fullscreen={true}
                                />
                            </React.Fragment>
                        }
                    </div>}
                </div>
            </section>
        </React.Fragment>

    )

}
export default VideoLandingPage;